.App {
    text-align: center;
    background: #659999;  /* fallback for old browsers */


}
body, html {
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
}
.MuiAppBar-colorPrimary {
    background-color: #659999 !important;
}